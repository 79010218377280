<template>
  <!--begin::Advance Table Widget 9-->
  <div class="card card-custom card-stretch gutter-b">

    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <div class="card-toolbar">
        <a
          href="#"
          class="btn btn-primary font-weight-bolder font-size-sm"
          @click.prevent="onUploadFileClicked()"
        >
          <i class="menu-icon flaticon2-plus" style="font-size: 1em"></i>Ladda upp fil
        </a>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-0 pb-3">
      <!--begin::Table-->
      <div class="table-responsive">
        <table class="table table-head-custom table-vertical-center table-head-bg table-borderless">
          <thead>
            <tr class="text-left">
              <th style="min-width: 110px">Namn</th>
              <th style="min-width: 110px">Datum</th>
              <th style="min-width: 140px">Beskrivning</th>
              <th style="min-width: 110px">Summa</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, i) in files">
              <GrantFileTableRow
                v-bind:key="i"
                :file="item"
                :type="type"
                :currentCompanyId="currentCompanyId"
                @downloadFileClicked="downloadFileClicked"
                @selectFileClicked="selectFileClicked"
                @deleteFileClicked="deleteFileClicked"
                @onSumUpdated="onSumUpdated"
                @onDescrUpdated="onDescrUpdated"

                @toastr="toastr"
              />
            </template>
          </tbody>
        </table>
      </div>
      <!--end::Table-->
    </div>
    <!--end::Body-->
  </div>
  <!--end::Advance Table Widget 9-->
</template>
<style lang="scss" scoped>

</style>


<script>
import axios from 'axios';
import GrantFileTableRow from '@/view/pages/ml/grantwizard/GrantFileTableRow.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'grant-file-table',
  props: {
    files: Array,
    currentCompanyId: String,
    type: String,
    taskStatus: {type: String, default: 'CLOSE'}
  },
  emits: ['downloadFileClicked', 'selectFileClicked', 'deleteFileClicked', 'onSumUpdated', 'onDescrUpdated', 'onUploadFileClicked'],
  components: {
    GrantFileTableRow
  },
  watch: {
    files(newValue, oldvalue) {

    }
  },
  mounted() {

  },
  methods: {
    downloadFileClicked(file_id) {
      this.$emit('downloadFileClicked', file_id);
    },
    selectFileClicked(file_id) {
      this.$emit('selectFileClicked', file_id);
    },
    deleteFileClicked(file_id) {
      this.$emit('deleteFileClicked', file_id);
    },
    onUploadFileClicked() {
      this.$emit('onUploadFileClicked');
    },
    onSumUpdated(file_id, amount) {
      this.$emit('onSumUpdated', file_id, amount);
    },
    onDescrUpdated(file_id, descr) {
      this.$emit('onDescrUpdated', file_id, descr);
    },

  },
  data() {
    return {
      list: []
    };
  }
};
</script>
